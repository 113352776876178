import React, { Component } from "react"
import { graphql } from "gatsby"
import './../assets/scss/index.scss'
// import ContactFooter from '../components/contactfooter'
import SEO from "../components/seo"
import ScroolTop from '../components/scrolltop'

class Privacypolicy extends Component {
  
  render() {
    const data = this.props.data;    
    const page = data.wordpressPage.content;
    //console.log(page);

    
   
    return (
      <>
        <SEO title="Privacy policy" />        
        <div className="page-header privacy-policy-header">
          <div className="container">
              <div className="header-inner">
                <h1>Privacy Policy</h1>
              </div>
          </div>
      </div>
        <div className="page-content privacy-policy">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">                      
                      <div className="content" dangerouslySetInnerHTML={{ __html: page }} />                                          
                  </div>
              </div>
          </div>
        </div>   
        <ScroolTop /> 
      </>
    )}}

export default Privacypolicy

export const query = graphql`
{
    wordpressPage(wordpress_id: {eq: 3}) {
        content
    }  
}
`
